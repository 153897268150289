<template>
  <section id="card-text-alignment">
    <section id="search-section">
      <b-row>
        <b-col md="12">
          <b-card>
            <Search></Search>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <section id="items">
      <b-row id="table-hover-row">
        <b-col md="12">
          <b-card>
            <div class="table-responsive">
              <Products/>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <Edit/>
    <ProductImages2></ProductImages2>
    <OrderProductStatistics/>
    <EditProductBarcodes/>
    <StoreProductDetail/>
  </section>
</template>

<script>
import Search from "@/views/product/Search";
import Products from "@/views/product/Products";
import Edit from "@/views/product/Edit";
import ProductImages2 from "@/components/ProductImages2";
import OrderProductStatistics from "@/views/order/list/OrderProductStatistics";
import EditProductBarcodes from "@/views/product/EditProductBarcodes";
import StoreProductDetail from "@/views/product/StoreProductDetail.vue";


export default {
  name: "ProductHome",
  components: {Search, Products, Edit, ProductImages2, OrderProductStatistics, EditProductBarcodes, StoreProductDetail},
  created() {
    this.$store.dispatch('productBrand/getAll');
  }
}
</script>

<style scoped>

</style>
